import React, { useEffect, useMemo, useRef, useState } from 'react';

import './Slider.scss';
import clsx from 'clsx';

export enum ControlMode {
	hide,
	write,
	read,
}

export enum Orientation {
	horizontal,
	vertical,
}

export interface SliderProps<ValueType = number> {
	className?: string;
	style?: React.CSSProperties;
	onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;

	// Value
	min?: number;
	max?: number;
	step?: number;
	value?: ValueType;
	controlValue?: ValueType;
	centerValue?: ValueType;
	syncValue?: boolean;
	returnToCenter?: boolean;
	controlMode?: ControlMode;
	onChange?: (value: ValueType) => void;

	// Style
	sliderWidth?: string;
	sliderHeight?: string;
	orientation?: Orientation;
	trackStyle?: React.CSSProperties | React.CSSProperties[];
	handleStyle?: React.CSSProperties | React.CSSProperties[];
	railStyle?: React.CSSProperties;
	dotStyle?: React.CSSProperties;
	activeDotStyle?: React.CSSProperties;

	// onUserControlChange?: ()
}

export interface SliderRef {
	focus: () => void;
	blur: () => void;
}

export type OnStartMove = (
	e: React.MouseEvent | React.TouchEvent,
	valueIndex: number
) => void;

function ratio(value: number, min: number, max: number) {
	return (value - min) / (max - min);
}

const Slider = React.forwardRef(
	(props: SliderProps, ref: React.Ref<SliderRef>) => {
		const {
			className,
			style,
			onFocus,
			onBlur,

			// Value
			min = -100,
			max = 100,
			step = 1,
			value = 0,
			controlValue = 0,
			centerValue = 0,
			returnToCenter = false,
			controlMode = ControlMode.write,
			onChange,

			// Style
			sliderWidth = '300px',
			sliderHeight = '50px',
			orientation = Orientation.horizontal,
		} = props;

		// 중앙 위치
		const centerPos = useMemo(() => {
			return ratio(centerValue, min, max);
		}, [centerValue, min, max]);

		// 값 위치
		const valuePos = useMemo(() => {
			return ratio(value, min, max);
		}, [value, min, max]);

		const [lastControlTime, setLastControlTime] = useState(0);
		const [userControlValue, setUserControlValue] = useState(controlValue);

		const time = new Date().getTime();
		const isInUserControl = time - lastControlTime < 100;

		useEffect(() => {
			if (returnToCenter && !isInUserControl) {
				if (onChange) onChange(centerValue);
			}
		}, [isInUserControl, onChange, centerValue, returnToCenter]);

		function orientedSwitch<T>(horizontal?: T, vertical?: T): T | undefined {
			const orient = orientation === Orientation.horizontal;
			return orient ? horizontal : vertical;
		}
		function orientedStyle({
			width,
			height,
			left,
			right,
			top,
			bottom,
			marginLeft,
			marginRight,
			marginTop,
			marginBottom,
		}: React.CSSProperties): React.CSSProperties {
			return {
				width: orientedSwitch(width, height),
				height: orientedSwitch(height, width),
				left: orientedSwitch(left, bottom),
				right: orientedSwitch(right, top),
				top: orientedSwitch(top, right),
				bottom: orientedSwitch(bottom, left),
				marginLeft: orientedSwitch(marginLeft, marginBottom),
				marginRight: orientedSwitch(marginRight, marginTop),
				marginTop: orientedSwitch(marginTop, marginRight),
				marginBottom: orientedSwitch(marginBottom, marginLeft),
			};
		}

		const controlCircleSize = 16;

		return (
			<div
				className={clsx('Slider', className)}
				style={{
					...orientedStyle({
						width: sliderWidth,
						height: sliderHeight,
					}),
					boxSizing: 'content-box',
					position: 'relative',
					...style,
				}}
			>
				<div
					className="valueRange"
					style={{
						...orientedStyle({
							width: `calc(100% - ${controlCircleSize}px)`,
							height: '100%',
							marginLeft: `${controlCircleSize / 2}px`,
							marginRight: `${controlCircleSize / 2}px`,
						}),
						position: 'absolute',
					}}
				>
					<div
						className="rail"
						style={{
							...orientedStyle({
								width: '100%',
								height: `calc(${sliderHeight} - ${controlCircleSize}px)`,
								marginTop: `${controlCircleSize / 2}px`,
								marginBottom: `${controlCircleSize / 2}px`,
							}),
							position: 'absolute',
							boxSizing: 'content-box',
							backgroundColor: 'rgb(102, 102, 102)',
							borderRadius: '0px',
						}}
					>
						<div
							className="value"
							style={{
								...orientedStyle({
									width: `${
										(Math.max(centerPos, valuePos) -
											Math.min(centerPos, valuePos)) *
										100
									}%`,
									height: `calc(${sliderHeight} - ${controlCircleSize}px)`,
									left: `${Math.min(centerPos, valuePos) * 100}%`,
								}),
								position: 'absolute',
								backgroundColor: 'orange',
								borderRadius: '0px',
							}}
						></div>
					</div>
					<div
						className="center"
						style={{
							...orientedStyle({
								width: '2px',
								height: sliderHeight,
								marginLeft: '-1px',
								left: `${centerPos * 100}%`,
							}),
							position: 'absolute',
							boxSizing: 'content-box',
							background: '#999999',
						}}
					></div>
				</div>

				<input
					type="range"
					value={(isInUserControl ? userControlValue : controlValue) / step}
					min={min / step}
					max={max / step}
					step={step}
					disabled={controlMode !== ControlMode.write}
					onChange={(e) => {
						const userControlValue = parseInt(e.target.value) * step;
						setUserControlValue(userControlValue);
						setLastControlTime(new Date().getTime());

						if (onChange) onChange(userControlValue);
					}}
					style={{
						transform: orientedSwitch('none', 'rotate(270deg)'),
						transformOrigin: 'top left',
						marginTop: orientedSwitch('none', sliderWidth),
						width: sliderWidth,
						height: sliderHeight,
					}}
				></input>
			</div>
		);
	}
);
export default Slider;
